<template>
  <div v-loading="loading">
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      :before-close="handleCloseDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <!-- {{ mcxStockList }} -->
      <!-- {{nseStockList}}
           {{othStockList}} -->

      <el-form
        :model="formData"
        :rules="formRules"
        ref="refForm"
        label-position="top"
        :status-icon="true"
      >
         <el-tabs class="marketwatch_tab dialog_marketwatch" type="border-card">
              <el-tab-pane label="MCX">
                <el-row class="master-script">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item
                            :error="formErrors.script"
                            label=""
                            prop="script"
                            :label-width="formLabelWidth"
                          >
                          <br>
                            <el-checkbox-group v-model="formData.mcx_script">
                              <el-checkbox v-for="(script, index) in mcxStockList" :key="index"
                                :label="script.stock_id" checked>{{script.name}}</el-checkbox>
                            </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <el-tab-pane label="NSE">
                 <el-row class="master-script">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item
                            :error="formErrors.script"
                            label=""
                            prop="script"
                            :label-width="formLabelWidth"
                          >
                          <br>
                            <el-checkbox-group v-model="formData.nse_script">
                              <el-checkbox v-for="(script, index) in nseStockList" :key="index"
                                :label="script.stock_id" checked>{{script.name}}</el-checkbox>
                            </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-tab-pane>
              <!-- <el-tab-pane label="OTH">
                 <el-row class="master-script">
                  <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="24">
                    <el-form-item
                            :error="formErrors.script"
                            label=""
                            prop="script"
                            :label-width="formLabelWidth"
                          >
                          <br>
                            <el-checkbox-group v-model="formData.oth_script">
                              <el-checkbox v-for="(script, index) in othStockList" :key="index"
                                :label="script.stock_id">{{script.name}}</el-checkbox>
                            </el-checkbox-group>
                    </el-form-item>
                  </el-col>
                </el-row>
              </el-tab-pane> -->
            </el-tabs>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <!-- <el-button @click="handleCloseDialog()"> Cancel </el-button> -->
        <!-- <el-button type="primary" @click="sendChildFormData()">Save</el-button> -->
        <el-button @click="handleCloseDialog()" type="primary" >Save</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { requiredRule } from "@/rules/all-rules";
export default {
  name: "AddEditDialog",
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    dialogType: {
      type: String,
      default: "",
    },
    formData: {
      type: Object,
      default: function () {
        return {
        //   mcx_script: [],
        //   nse_script: [],
        //   oth_script: [],
      };
      },
    },
    mcxStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    nseStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    othStockList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    formErrors: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      loading: false,
      formRules: {},
      formLabelWidth: "120px",
    };
  },
  computed: {
    dialogTitle() {
      return this.dialogType === "create" ? "Block Script (un-checke for block script)" : "Block Script (un-checke for block script)";
    },
  },
  created() {
    /**
     * Including  Of Validation Rule
     */
    this.formRules = {
      newPassword: requiredRule("newPassword"),
    };
  },
  methods: {
    handleCloseDialog() {
      this.$emit("childClose");
    },
    sendChildFormData() {
      this.loading = true;
      this.$refs.refForm.validate((valid) => {
        if (valid) {
          // WHEN EDIT MERGE NEW PARAMETERS
          if (this.dialogType === "edit") {
            this.formData = Object.assign(this.formData, {
              dialog_type: this.dialogType,
            });
          }
          console.log(this.formData.newPassword);
          this.$emit("getChildFormData", this.formData.newPassword);
          this.loading = false;
        }
      });
    },
  },
};
</script>
